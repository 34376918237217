<template>
  <Container :theme="theme">
    <!-- 新增按钮 -->
    <!-- <AddButton @click="addPayment" v-show="showAddButton"></AddButton> -->
    <slot-header :showIcon="true" :showBackIcon="true" @onBack="goBack" @onIconClick="addPayment" :imageName="imageName">
      <div>{{ $route.meta.name }}</div>
    </slot-header>
    <!-- 显示目前项目 -->
    <template v-if="showAddButton">
      <Modal :content="content" :closeModal="closeModal" @click="closeModalHandler">
        <div slot="footer" class="btn_group">
          <button class="btn_default btn_secondary" @click="cancelModal">
            取消
          </button>
          <button class="btn_default btn_primary" @click="checkHandler">
            {{ checkButton }}
          </button>
        </div>
      </Modal>
      <div v-if="checkResponseStatus">
        <div class="pt_75rem">
          <div class="list-item" v-for="(item, index) in accountData" :key="item.title" data-type="0">
            <div class="list_box" @touchstart.capture="touchStart" @touchend.capture="touchEnd" @click="skip">
              <div class="list_content">
                <div class="list_content_title">
                  <div class="title">{{ item.accountName }}</div>
                  <div class="title sub_title" v-if="item.channelId !== 2 && item.bankName">
                    {{ item.bankName }}
                  </div>
                  <div class="title sub_title" v-if="item.channelId === 2 && item.account">
                    {{ item.account }}
                  </div>
                </div>
                <div class="list_content_title_bold">
                  {{ item.channelId !== 2 ? item.account : item.accountMobile }}
                </div>
              </div>
              <div class="icon" @click="toggleStatus(item.id)">
                <img src="../../../assets/images/public/ic_com_off.png" v-if="!item.status" />
                <img src="../../../assets/images/public/ic_com_on.png" v-else />
              </div>
            </div>
            <div class="delete" @click="deleteItem(item.id)" :data-index="index">
              删除
            </div>
          </div>
        </div>
      </div>
      <NoData v-else></NoData>
    </template>
  </Container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import SlotHeader from "@/components/SlotHeader";

import NoData from "@/components/NoData";
// import AddButton from "../components/AddButton";
import Modal from "../components/Modal";
import Container from "../components/Container";
export default {
  components: {
    Modal,
    // AddButton,
    SlotHeader,

    NoData,
    Container,
  },
  data() {
    return {
      id: "",
      status: "",
      closeModal: false,
      showAddButton: true,
      paymentsId: {
        alipay: {
          id: 1,
        },
        WeChat: {
          id: 2,
        },
        BankCard: {
          id: 3,
        },
      },
      imageName: "images/public/ic_my_security_add.webp",
      data: [],
      currentStatus: "",
      modal: {
        setPassword: {
          content: "您还未設定安全密碼，是否去设置?",
          checkButton: "确定",
        },
        activateAccount: {
          content: "确认启用此账号吗?",
          checkButton: "启用",
        },
        deactivatedAccount: {
          content: "确认停用此账号吗?",
          checkButton: "停用",
        },
        deleteAccount: {
          content: "确认删除此账号吗?",
          checkButton: "删除",
        },
      },
      startX: 0,
      endX: 0,
    };
  },
  computed: {
    ...mapState("users", ["usersData"]),
    ...mapState("account", ["accountData"]),
    checkResponseStatus() {
      return this.accountData && this.accountData.length > 0 ? true : false;
    },
    isSecurityCode() {
      return this.usersData ? this.usersData.isSecurityCode : false;
    },
    payment() {
      return this.$route.query.method;
    },
    channelId() {
      return this.payment ? this.paymentsId[this.payment].id : 0;
    },
    content() {
      return this.currentStatus !== "" ? this.modal[this.currentStatus].content : "";
    },
    checkButton() {
      return this.currentStatus !== "" ? this.modal[this.currentStatus].checkButton : "确定";
    },
    theme() {
      return this.checkResponseStatus ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions("account", ["getAccounts"]),
    ...mapActions("account", ["updateAccount"]),
    ...mapActions("account", ["deleteAccount"]),
    toggleShowAddButton() {
      this.showAddButton = !this.showAddButton;
    },

    goBack() {
      this.$router.push({
        path: "/my/paymentMethod",
      });
    },
    addPayment() {
      if (!this.isSecurityCode) {
        this.currentStatus = "setPassword";
        this.closeModal = false;
      } else {
        this.toggleShowAddButton();
        this.$router.push({
          path: "/my/paymentMethod/addPayment",
          query: { method: this.payment },
        });
      }
    },
    closeModalHandler(e) {
      if (e.className.includes("backdrop")) {
        this.closeModal = true;
      }
    },
    cancelModal() {
      this.closeModal = true;
    },
    checkHandler() {
      if (this.currentStatus === "setPassword") {
        this.$router.push({ path: "/my/paymentMethod/security" });
      } else {
        this.updateAccountStatus(this.id, this.status);
      }
    },
    //跳转
    skip() {
      if (this.checkSlide()) {
        this.restSlide();
      } else {
        return false;
      }
    },
    touchStart(e) {
      // 滑动开始,记录初始位置
      this.startX = e.touches[0].clientX;
    },
    touchEnd(e) {
      //滑动结束，当前滑动的父级元素
      let parentElement = e.currentTarget.parentElement;
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;

      // 左滑
      if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
        this.restSlide();
        parentElement.dataset.type = 1;
        e.currentTarget.nextElementSibling.style.display = "flex";
        e.currentTarget.getElementsByClassName("icon")[0].className = "icon mr_4rem";
      }

      // 右滑
      if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
        this.restSlide();
        parentElement.dataset.type = 0;
      }

      this.startX = 0;
      this.endX = 0;
    },
    checkSlide() {
      //判断当前是否有滑块处于滑动状态
      let listItems = document.querySelectorAll(".list-item");
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].dataset.type == 1) {
          return true;
        }
      }
      return false;
    },
    restSlide() {
      //复位滑动状态
      let listItems = document.querySelectorAll(".list-item");
      // 复位
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].dataset.type = 0;
        listItems[i].getElementsByClassName("delete")[0].style.display = "none";
        listItems[i].getElementsByClassName("icon")[0].className = "icon";
      }
    },
    deleteItem(id) {
      // 复位
      this.restSlide();
      // 删除
      this.deleteAccount({
        id: id,
      }).then(() => {
        this.getAccounts({
          channelId: this.channelId,
        });
      });
    },
    toggleStatus(id) {
      this.closeModal = false;

      let { status } = this.accountData.filter((account) => account.id === id)[0];
      this.currentStatus = status === 1 ? "deactivatedAccount" : "activateAccount";

      this.id = id;
      this.status = status === 1 ? 0 : 1;
    },
    updateAccountStatus(id, newStatus) {
      this.closeModal = true;
      this.updateAccount({
        id: id,
        status: newStatus,
      }).then(() => {
        this.getAccounts({
          channelId: this.channelId,
        });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    // 渠道ID 1=支付宝 2=微信支付 ,3=银行卡
    let payments = {
      alipay: {
        title: "支付宝",
        id: 1,
      },
      WeChat: {
        title: "微信",
        id: 2,
      },
      BankCard: {
        title: "银行卡",
        id: 3,
      },
    };
    to.meta.name = payments[to.query.method].title ? payments[to.query.method].title : "银行卡";
    next();
  },
  mounted() {
    this.closeModal = true;
    this.getAccounts({
      channelId: this.channelId,
    });
  },
};
</script>

<style lang="scss" scoped>
.btn {
  &_group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.65rem 0rem;
    border-radius: 20px;
    margin: 0 0.5rem;
    font-size: 1rem;
    min-width: 40%;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }

  &_secondary {
    color: white;
    background-color: var(--light-black);
  }
}

.pt_75rem {
  margin-top: 0.75rem;
}

.list_content_title {
  display: flex;
}

.list-item {
  position: relative;
  transition: all 0.2s;
  margin-bottom: 1rem;
  padding: 3rem;
}

.list-item[data-type="0"] {
  transform: translate3d(0, 0, 0);
}

.list-item[data-type="1"] {
  transform: translate3d(-2rem, 0, 0);
}

.list-item:after {
  content: " ";
  position: absolute;
  left: 0.2rem;
  bottom: 0;
  right: 0;
  height: 1px;
  color: #ccc;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  z-index: 2;
}

.list_box {
  padding: 0.75rem;
  background: #fff;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0;
  border-radius: 0.5rem;
  text-align: left;
}

.list-item .list_content {
  padding: 0.1rem 0 0.1rem 0.2rem;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.list-item .title {
  color: #333;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 1rem;

  &.sub_title {
    color: var(--gray);
    padding-left: 0.5rem;
    font-size: 0.9rem;
    font-weight: normal;
  }
}

.list-item .list_content_title_bold {
  display: block;
  overflow: hidden;
  font-weight: bold;
  font-size: 1rem;
  color: rgb(0, 0, 0);
  line-height: 1.5;
  width: 10rem;
}

.list-item .delete {
  display: none;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  background: rgb(255, 94, 0);
  font-size: 17px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  right: -2.5rem;
  border-radius: 0 0.75rem 0.75rem 0;
  padding: 0 1.5rem;
}

.icon {
  display: block;
  width: 2.5rem;
  margin-right: 0.75rem;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.mr_4rem {
  margin-right: 4rem;
}
</style>
