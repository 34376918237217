<template>
  <div class="backdrop" :class="{ closeModal: closeModal }" @click="$emit('click', $event.target)">
    <div class="modal">
      <div class="modal_title" v-if="header !== ''">
        {{ header }}
      </div>
      <p class="modal_content">
        {{ content }}
      </p>
      <div>
        <slot name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    header: {
      type: String,
      default: "提示",
    },
    content: {
      type: String,
      default: "您还未設定安全密碼，是否去设置?",
    },
    closeModal: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  background-color: #1a1a1a80;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: 200px;
  width: 80vw;
  z-index: 1;
  border-radius: 0.75rem;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 2rem 1rem;
  // max-width: 414px;
  @media (min-width: 480px) {
    max-width: unset;
  }
  &_title {
    color: var(--orange);
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  &_content {
    margin-bottom: 2rem;
    line-height: 1.5rem;
  }
}

.backdrop.closeModal {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s ease-in-out;
}
</style>
